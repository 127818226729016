/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


import moment from 'moment';

import vSelect from "vue-select";
import {createApp} from "vue";
import ApplicationCity from './components/ApplicationCity.vue';
import ApplicationSelect from './components/ApplicationSelect.vue';
import PaymentComponent from './components/PaymentComponent.vue';
import AdditionalFields from './components/AdditionalFields.vue';
import UsersSelectComponent from './components/UsersSelectComponent.vue';
import BalanceImporter from "./components/BalanceImporter.vue";

require('./bootstrap');
require('lodash');
require('webpack-jquery-ui/css');
require('admin-lte/dist/js/adminlte.min');
require('admin-lte/plugins/select2/js/select2.full.min');
window.echarts = require('echarts');

require('trumbowyg/dist/trumbowyg.min');
require('trumbowyg/dist/langs/pl.min');
window.moment = moment;
require('jquery-datetimepicker-vishnu8742/build/jquery.datetimepicker.full.min');

const PDFObject = require('pdfobject/pdfobject.min');

jQuery.datetimepicker.setLocale('pl');

const searchSelectFields = ['nationality', 'note', 'reminder_type_id', 'type', 'application_id', 'has_repossession', 'allow_ask_payment_without_documents', 'status', 'user_id', 'coordinator_id', 'user_withdrawals', 'tax_type', 'role_id', 'step', 'internal_company_id', 'user_internal_company_id', 'used', 'vat', 'settled_status', 'invoice_type_id'];
const searchInputFields = ['active_from', 'active_from_from', 'active_from_to', 'active_to', 'active_to_from', 'active_to_to', 'search', 'date_settled_from', 'date_settled_to', 'date_from', 'updated_date_from', 'updated_date_to', 'date_to', 'date_buy', 'accepted_user_from', 'accepted_user_to', 'accepted_to', 'accepted_from'];
const searchCheckboxFields = ['with_deleted', 'has_lease_agreement', 'has_contract_of_mandate', 'has_reimbursement', 'has_b2b', 'has_cash', 'has_service'];

const globalMethods = {};
const globalFilters = {

    toCurrency(value) {
        if (typeof value !== 'number') {
            console.log(value, 'not number');
            return value;
        }
        var formatter = new Intl.NumberFormat('pl-PL', {
            style: 'currency',
            currency: 'PLN',
            minimumFractionDigits: 2
        });
        return formatter.format(value);
    },
    formatDate(value) {
        if (value) {
            return moment(String(value)).format('DD-MM-YYYY hh:mm');
        }
    }
};
const allFields = [
    ...searchSelectFields,
    ...searchInputFields,
    ...searchCheckboxFields
];

const app = createApp({});
app.config.globalProperties.$methods = globalMethods;
app.config.globalProperties.$filters = globalFilters;
_.forEach(allFields, (field) => {
    app.config.globalProperties[field] = '';
});


app.component('application-city-component', ApplicationCity);
app.component('application-select-component', ApplicationSelect);
app.component('balance-importer-component', BalanceImporter);
app.component('payment-component', PaymentComponent);
app.component('additional-fields-component', AdditionalFields);
app.component('users-select-component', UsersSelectComponent);
app.component("v-select", vSelect);

app.mount('#app');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */



function filterDocs() {
    var type = $('[name=type]:checked').val();
    var driverType = $('[name=driver-type]:checked').val();
    // var isPow26 = $('[name=pow26]').is(':checked');
    // var isPon26 = $('[name=pon26]').is(':checked');
    var isStudent = $('[name=student]').is(':checked');
    var isObcokrajowiec = $('[name=obcokrajowiec]').is(':checked');

    var allRows = $('.document-table tr');
    var wspolpracaRow = $('.wspolpraca-row');
    var zlecenieRow = $('.zlecenie-row');
    var titleRow = $('.title-row');
    var najemRow = $('.najem-row');
    var paszportRow = $('.paszport-row');
    var zlecenieRowspan = $('.zlecenie-rowspan');
    var legitymacjaRow = $('.legitymacja-row');

    // console.log(type, driverType, isPow26, isPon26, isStudent, isObcokrajowiec);
    if (type === 'prywatna') {
        $('.prywatna-addons').show();
    } else {
        $('.prywatna-addons').hide();
    }
    allRows.show();
    zlecenieRowspan.attr('rowspan', 7);
    switch (type) {
        case 'prywatna':
            wspolpracaRow.hide();
            if (!isObcokrajowiec) {
                paszportRow.hide();
                zlecenieRowspan.attr('rowspan', +zlecenieRowspan.attr('rowspan') - 1);
            }
            if (!isStudent) {
                legitymacjaRow.hide();
                zlecenieRowspan.attr('rowspan', +zlecenieRowspan.attr('rowspan') - 2);
            }

            break;
        case 'b2b':
            allRows.hide();
            titleRow.show();

            switch (driverType) {
                case 'kurier':
                    wspolpracaRow.show();

                    break;
                case 'kierowca':
                default:
                    najemRow.show();
                    wspolpracaRow.show();

                    break;
            }

            break;
    }

}

function processImportRow($row) {


    const userId = $row.find('select[name^=user_id]').val();
    const data = {
        user_id: userId
    };
    $row.find('input').each(function () {
        const fullName = $(this).attr('name');
        const name = fullName.substr(0, fullName.length - 2);
        data[name] = $(this).val();
    });

    // Add headers[] inputs to data
    $row.parents('table')
        .find('thead input[name^=header]')
        .each(function () {
            const headerName = $(this).attr('name');
            data[headerName] = $(this).val();
        });
    return data;
}

async function sendBalances(dataToSend, url) {
    let send = 0;
    const total = dataToSend.length;
    $('.import-module').addClass('sending');

    while (dataToSend.length) {
        const percentage = Math.round(100 * send / total);
        $('.import-percentage').text(percentage);
        const payloadItem = dataToSend.shift();
        const tr = payloadItem.tr;
        tr.addClass('sending');
        _.unset(payloadItem, 'tr');
        await sendSingleBalance(payloadItem, url, tr);
        tr.remove();
        send++;
    }
    $('.import-module').removeClass('sending');

}

async function sendSingleBalance(data, url, tr) {
    return new Promise((resolve, reject) => {
        const payload = {};
        _.forEach(data, (item, key) => {
            _.set(payload, key, [item]);
        });
        axios({
            url: url,
            method: 'post',
            data: payload
        })
            .then(function (response) {
                setTimeout(() => {
                    resolve(response);
                }, 1000);
            })
            .catch(function (error) {
                tr.addClass('error');
                $('.import-module').removeClass('sending');
                console.error(error);
                reject(error);
            });
    });

}

function processImportTable($form) {
    const data = [];
    $form.find('tbody tr').each(function () {
        const record = processImportRow($(this));
        _.set(record, 'tr', $(this));
        data.push(record);
    });
    sendBalances(data, $form.attr('action'));
}

$(document).ready(function () {

    $('.import-module form').submit(function (e) {
        e.preventDefault();
        processImportTable($(this));
    });


    $('.filter-box input').click(function () {
        filterDocs();
    });

    $('.confirm-delete').on('submit', function () {
        if (confirm('Czy na pewno usunąć?')) {
            return true;
        }
        return false;
    });


    $('.confirm-pending-delete').click(function (e) {
        e.preventDefault();

        const comment = prompt('Podaj powód odrzucenia');
        if (comment === null) {
            return;
        }
        $(this).parent().find('input[name=comment]').val(comment);
        $(this).parents('form').submit();

    });


    $('.invoice-form,.block-form-on-submit').on('submit', function () {
        $(this).find('[type="submit"]').attr('disabled', 'disabled');
    });
    $('.payment-form').on('submit', function () {
        const customEvent = new CustomEvent('onFormSubmit');
        document.dispatchEvent(customEvent);
    });

    $('.select2-jq').select2({

        theme: 'bootstrap4'
    });
    $('.select2-users').each(function () {
        const role = $(this).data('role');
        $(this).select2({
            theme: 'bootstrap4',
            placeholder: 'Wyszukaj',
            language: {
                // You can find all of the options in the language files provided in the
                // build. They all must be functions that return the string that should be
                // displayed.
                inputTooShort: function () {
                    return "Wpisz min 3 znaki...";
                },
                searching: function () {
                    return "Szukam...";
                },
                noResults: function () {
                    return "Brak wyników"
                },
                loadingMore: function () {
                    return 'Trwa ładowanie…';
                },
                errorLoading: function () {
                    return 'Nie można załadować wyników.';
                },
            },
            minimumInputLength: 3,
            allowClear: true,
            ajax: {
                delay: 250,
                url: window.listUrl ? window.listUrl : `/admin/user/list?role=${role ? role : ''}`,
                dataType: 'json',
                data: function (params) {
                    return {
                        search: encodeURIComponent(params.term),
                        page: params.page || 1
                    }
                }
            }
        });
    });
    $('.import-module input[type=submit]').prop('disabled', false);


    $('.datepicker').each(function () {
        const tt = $(this);

        const minDate = tt.data('min-date');

        tt.datetimepicker({
            minDate: minDate,
            timepicker: false,
            dayOfWeekStart: 1,
            format: 'Y-m-d',
            scrollInput: false,
        });


    });
    $('.datetimepicker').each(function () {
        const tt = $(this);

        const minDate = tt.data('min-date');

        tt.datetimepicker({
            minDate: minDate,
            dayOfWeekStart: 1,
            format: 'Y-m-d H:i',
            scrollInput: false,
        });

        // $(`#${id}`).datetimepicker({
        //     locale: 'pl',
        //     format: 'yyyy-MM-DD HH:mm',
        //     minDate: minDate,
        //
        // });

    });

    $('.datepicker-month').datetimepicker({
        closeOnWithoutClick: false,
        format: 'Y-m-d',
        dayOfWeekStart: 1,
        // autoclose: true,
        timepicker: false,


        // changeMonth: true,
        // changeYear: true,
        // showButtonPanel: true,
        // onClose: function (dateText, inst) {
        //     console.log(dateText, inst);
        //     $(this).datetimepicker('setDate', new Date(dateText.getYear(), inst.selectedMonth, 1));
        // }
    });

    $('.message-card .card-header').click(function () {
        $(this).next().toggle();
        const href = $(this).data('href');
        if (!href) {
            return;
        }
        axios.post(href, {})
            .then((response) => {
                $(this).find('span').remove();
                $(this).removeAttr('data-href');
            })
            .catch(function (error) {
                console.log(error);
            });
    });

    $('.remove-media-bt').click(function (e) {
        e.preventDefault();
        if (confirm("Czy na pewno chcesz usunąć?") == true) {
            axios.post($(this).attr('href'), {
                id: $(this).data('id'),
            })
                .then((response) => {
                    $(this).parents('.list-group-item').remove();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    });
    $('.remove-media-comment-bt').click(function (e) {
        e.preventDefault();

        const comment = prompt('Wprowadź komentarz');
        if (comment === null) {
            return;
        }

        axios.post($(this).attr('href'), {
            id: $(this).data('id'),
            comment
        })
            .then((response) => {
                $(this).parents('.list-group-item').remove();
            })
            .catch(function (error) {
                console.log(error);
            });
    });
    $('.accept-media-bt').click(function (e) {
        e.preventDefault();
        if (confirm("Czy na pewno chcesz zaakceptować?") == true) {
            axios.post($(this).attr('href'), {
                id: $(this).data('id'),
            })
                .then((response) => {
                    $(this).remove();
                    location.reload();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    });
    setInterval(function () {
        $(".invoice-lookup").each(function () {
            $(this).contents().find("img").css('width', '100%');
        });
    }, 300);

    $('.remove-invoice-media-bt').click(function (e) {
        e.preventDefault();
        if (confirm("Czy na pewno chcesz usunąć?") == true) {
            axios.post($(this).attr('href'), {
                id: $(this).data('id'),
                invoiceId: $(this).data('invoice'),
            })
                .then((response) => {
                    $(this).parents('.list-group-item').remove();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    });
    const settleCBs = $('.settle-cb');

    settleCBs.change(function () {
        if (_.size(settleCBs.filter(':checked')) > 0) {
            let totalVat = 0;
            let totalCost = 0;
            settleCBs.filter(':checked').each(function () {
                totalVat += +($(this).parent().find('.vat').val());
                totalCost += +($(this).parent().find('.cost').val());
            });
            totalVat = Math.round(100 * totalVat) / 100;
            totalCost = Math.round(100 * totalCost) / 100;

            $('.total-vat-return').text(totalVat);
            $('.total-cost-return').text(totalCost);
            $('.settle-actions').show();

        } else {
            $('.settle-actions').hide();

        }
    });

    $('.settle-single-ok-bt').click(function (e) {
        e.preventDefault();
        if (confirm("Czy na pewno?") == true) {
            const level = $(this).data('level');
            $(this).parents('tr').find('.settle-cb').prop('checked', true);
            sendSettle(1, '', level);
        }
    });
    $('.settle-single-fail-bt').click(function (e) {
        e.preventDefault();
        if (confirm("Czy na pewno?") == true) {
            $(this).parents('tr').find('.settle-cb').prop('checked', true);
            sendSettle(-1);
        }
    });
    $('.settle-single-fail-bt2').click(function (e) {
        e.preventDefault();

        const comment = prompt('Wprowadź komentarz');
        if (comment === null) {
            return;
        }
        $(this).parents('tr').find('.settle-cb').prop('checked', true);
        sendSettle(-2, comment);

    });
    $('.select-all').click(function (e) {
        e.preventDefault();
        $(this).parents('table').find('[type=checkbox]').prop('checked', true);

    });


    $('.toggle-all-checkboxes').click(function (event) {
        $(this).parents('table').find('[type=checkbox]').prop('checked', this.checked);
    });

    $('.settle-ok-bt').click(function () {
        const level = $(this).data('level');
        if (confirm("Czy na pewno?") == true) {
            sendSettle(1, '', level);
        }
    });
    $('.settle-fail-bt').click(function () {
        if (confirm("Czy na pewno?") == true) {
            sendSettle(-1);
        }
    });
    $('.settle-fail-bt2').click(function () {
        const comment = prompt('Wprowadź komentarz');
        if (comment === null) {
            return;
        }
        sendSettle(-2, comment);
    });
    $('.settle-pending-bt').click(function () {
        if (confirm("Czy na pewno?") == true) {
            sendSettle(0);
        }
    });
    $('#selecctall').click(function (event) {
        if (this.checked) {
            $('.settle-cb').each(function () { //loop through each checkbox
                $(this).prop('checked', true); //check
            });
        } else {
            $('.settle-cb').each(function () { //loop through each checkbox
                $(this).prop('checked', false); //uncheck
            });
        }
        settleCBs.trigger('change');
    });
    $('#selectAll').click(function () {
        $('.single-withdrawal').prop('checked', this.checked).trigger('change');
    });
    $('.invoices-csv-bt').click(function (e) {
        e.preventDefault();
        const url = `${$(this).attr('href')}?${$('#invoice-search').serialize()}`;
        window.location.href = url;
    });
    $.trumbowyg.svgPath = '/images/icons.svg';
    $('.ckeditor').trumbowyg({
        lang: 'pl',
        // btns: ['viewHTML', '|', 'btnGrp-design', '|', 'link', 'insertImage', '|', 'btnGrp-justify', '|', 'btnGrp-lists', '|', 'foreColor', 'backColor', '|']
    });


    // $('.ckeditor').ckeditor({
    // 	language: 'pl',
    // 	plugins: [Bold, Italic, Underline, Strikethrough, Code, Subscript, Superscript],
    // toolbar: {
    // 	items: ['bold', 'italic', 'underline', 'strikethrough', 'code', 'subscript', 'superscript']
    // }
    // });

    // CKEDITOR.replace('wysiwyg-editor', {
    // 	filebrowserUploadUrl: "{{route('ckeditor.image-upload', ['_token' => csrf_token() ])}}",
    // 	filebrowserUploadMethod: 'form'
    // });


    $('[name=insurance_type]').click(
        function () {
            $('[name=insurance_other_employer_name_1]').removeAttr('required');
            $('[name=insurance_other_employer_1]').removeAttr('required');
            $('[name=insurance_school_1]').removeAttr('required');
            $('[name=insurance_other_employer_b_name_1]').removeAttr('required');
            $('[name=insurance_other_employer_b_1]').removeAttr('required');

            $('.insurance_type_specific').hide();

            if ($('[name=insurance_type]:checked').val() == 1) {
                $('.insurance_type_specific_1').show();

                $('[name=insurance_other_employer_name_1]').attr('required', true);
                $('[name=insurance_other_employer_1]').attr('required', true);

            } else if ($('[name=insurance_type]:checked').val() == 2) {
                $('.insurance_type_specific_2').show();

                $('[name=insurance_school_1]').attr('required', true);
            } else if ($('[name=insurance_type]:checked').val() == 3) {
                $('.insurance_type_specific_3').show();

                $('[name=insurance_other_employer_b_name_1]').attr('required', true);
                $('[name=insurance_other_employer_b_1]').attr('required', true);
            } else if ($('[name=insurance_type]:checked').val() == 4) {
                $('.insurance_type_specific_4').show();
            }
        }
    );
    $('[name=unemployed]').click(
        function () {
            if ($('[name=unemployed]').is(':checked')) {
                $('#unemloed_only_1').show();
                $('#unemloed_only_2').show();

                $('#unemloed_only_1').attr('required', true)
                $('#unemloed_only_2').attr('required', true)
            } else {
                $('#unemloed_only_1').hide();
                $('#unemloed_only_2').hide();

                $('#unemloed_only_1').removeAttr('required');
                $('#unemloed_only_2').removeAttr('required');
            }
        }
    );
    $('[name=statement_nonfarm]').click(
        function () {
            if ($('[name=statement_nonfarm]').is(':checked')) {
                $('#statement_nonfarm_amount').show();

                $('#statement_nonfarm_amount input').attr('required', true)
            } else {
                $('#statement_nonfarm_amount').hide();

                $('#statement_nonfarm_amount input').removeAttr('required');
            }
        }
    );

    $('[name=statement_other]').click(
        function () {
            if ($('[name=statement_other]').is(':checked')) {
                $('#statement_other').show();

                $('#statement_other input').attr('required', true)
            } else {
                $('#statement_other').hide();

                $('#statement_other input').removeAttr('required');
            }
        }
    );

    $('#referredUsersList #searchInput').on('keyup', function () {
        var searchValue = $(this).val().toLowerCase();
        $('#referredUsersList #usersTable tbody tr').filter(function () {
            $(this).toggle($(this).find('td:first').text().toLowerCase().indexOf(searchValue) > -1);
        });
    });

    // var $pageSize = 200;
    // var $imporedTable = $('.import-table');
    // var $imporedMoreBtn = $('.show-more-imported');
    // var $importedRows = $imporedTable.find('tr');
    // if ($importedRows.length < $pageSize) {
    // 	$imporedMoreBtn.hide();
    // 	$importedRows.show();
    // } else {
    // 	$importedRows.slice(0, $pageSize).show();
    // 	$imporedMoreBtn.click(function () {
    // 		$importedRows.is(':visible').next($pageSize).show();
    // 	});
    // }

    $(".pdf-embed").each(function () {
        const url = $(this).data('pdf');
        PDFObject.embed(url, $(this));
    });


    const calculateCBs = $('.calculate-cb');

    calculateCBs.change(function () {
        if (_.size(calculateCBs.filter(':checked')) > 0) {
            let totalCalculated = 0;
            let totalPercent = 0;
            calculateCBs.filter(':checked').each(function () {
                totalCalculated += +($(this).val());
            });
            totalCalculated = Math.round(100 * totalCalculated) / 100;
            totalPercent = Math.round(100 * totalCalculated * 0.085) / 100;

            $('.total-calculated').text(totalCalculated);
            $('.total-percent').text(totalPercent);
            $('.settle-actions').show();
        } else {
            $('.settle-actions').hide();

        }
    });

    $('#select-list-modal').on('show.bs.modal', function (event) {
        // Perform an AJAX call to get new items
        $.ajax({
            url: createUrlWithQueryParams('/admin/withdrawals', {settled_status: 0}), // Update with the correct route to fetch items
            method: 'GET',
            success: function (response) {
                // Assuming 'response' contains the list of new items
                generateList(response.data);
            },
            error: function (error) {
                console.error('Error fetching items:', error);
            }
        });
    });

    function formatNumber(number) {
        return number.toLocaleString('pl-PL', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.', ',');
    }

    function generateList(items) {
        var tbody = $('#select-list-modal .table tbody');
        tbody.empty();
        items.forEach(function (item) {
            var row = `
                <tr>
                    <td>
                        <input type="checkbox" class="single-withdrawal" value="${item.id}" name="selected_items[]"/>
                    </td>
                    <td>${item.user.name}</td>
                    <td>${item.bank_number ?? item.user.bank_number}</td>
                    <td>${moment(String(item.created_at)).format('DD-MM-YYYY hh:mm')}</td>
                    <td>${formatNumber(item.lease_agreement)} zł</td>
                    <td>${formatNumber(item.contract_of_mandate)} zł</td>
                    <td>${formatNumber(item.reimbursement)} zł</td>
                    <td>${formatNumber(item.b2b)} zł</td>
                    <td>${formatNumber(item.cash)} zł</td>
                    <td>${formatNumber(item.service)} zł</td>
                </tr>
            `;
            tbody.append(row);
        });
    }

    function createUrlWithQueryParams(baseUrl, params) {
        // Get the current URL
        const currentUrl = new URL(window.location.href);

        // Use URLSearchParams to work with the query string
        const searchParams = new URLSearchParams(currentUrl.search);

        // Delete the 'page' parameter
        searchParams.delete('page');
        Object.keys(params).forEach(key => searchParams.append(key, params[key]));

        // Return the base URL with the modified query string appended
        return `${baseUrl}?${searchParams}`;
    }

    $('#export-txt-modal').on('hidden.bs.modal', function () {
        // Refresh the page when the modal is closed
        window.location.reload();
    });

    $('#clearSearchForm').click(function () {
        $.each(searchSelectFields, (index, field) => {
            $(`[name=${field}]`).val('');
        });
        $.each(searchInputFields, (index, field) => {
            $(`[name=${field}]`).val('');
        });
        $.each(searchCheckboxFields, (index, field) => {
            $(`[name=${field}]`).prop('checked', false);
        });
    });

    const isGlobalCheckbox = $('input[id="is-global-checkbox"]');
    const applicationsSection = $('#applications-section');

    function toggleApplicationsSection() {
        if (isGlobalCheckbox.prop('checked')) {
            applicationsSection.css('display', 'none');
        } else {
            applicationsSection.css('display', 'flex');
        }
    }

    isGlobalCheckbox.on('change', toggleApplicationsSection);
    toggleApplicationsSection(); // Initial check
});

function sendSettle(status, comment = '',level) {
    var searchIDs = $('.settle-cb:checked').map(function () {
        return $(this).val();
    });
    axios.post(settleUrl, {
        ids: searchIDs.get(),
        status: status,
        comment,
        level,
    })
        .then((response) => {
            switch (status) {
                case 0:
                case 1:
                case -1:
                    window.location.reload();
                    break;
                case -2:
                    window.location.href = '/admin/invoice';
                    break;
            }
        })
        .catch(function (error) {
            console.error(error);
        });
}
