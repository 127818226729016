<script setup>

import {ref} from 'vue'
import _ from "lodash";

const props = defineProps({

    columnNames: {
        type: Array,
        required: true
    },
    sampleData: {
        type: Object,
        required: true
    },
    importer: {
        type: Object,
    }
});
const vats = [{
    value: 0,
    label: '0%'
}, {
    value: -8,
    label: '8% z brutto'
}, {
    value: 8,
    label: '8% z netto'
}, {
    value: -23,
    label: '23% z brutto'
}, {
    value: 23,
    label: '23% z netto'
},];
const actionNames = [
    {
        value: 'add',
        label: 'Dodaj'
    },
    {
        value: 'subtract',
        label: 'Odejmij'
    },
    {
        value: 'multiply',
        label: 'Pomnóż'
    },
];

const importer = ref(props.importer ? props.importer : {
    cash: [],
    online: [],
    tip: [],
    provision: [],
    externalId: [],
    additionalData: [],
    vats: [],
    filter: {
        active: false,
        field: '',
        condition: '',
        value: ''
    }
});

if (importer.value.filter === undefined) {
    _.set(importer.value, 'filter', {
        active: false,
        field: '',
        condition: '',
        value: ''
    });
}

if (importer.value.provision === undefined) {
    _.set(importer.value, 'provision', []);
}

const baseGroups = {
    cash: 'Gotówka',
    online: 'Płatności bezgotówkowe',
    provision: 'Baza prowizji',
    tip: 'Napiwki'
};

function getSampleValue(importerItem) {
    if (importerItem.isAbs) {
        return Math.abs(props.sampleData[importerItem.columnName]);
    }
    return props.sampleData[importerItem.columnName];
}

function removeItem(group, index) {
    let item = importer.value[group];
    if (index !== undefined) {
        item = importer.value[group][index].items;
    }
    item.pop();
}

function addItem(group, type, index) {
    let item = importer.value[group];
    if (index !== undefined) {
        item = importer.value[group][index].items;
    }

    if (type === 'column') {
        item.push({
            type: 'column',
            columnName: '',
            isAbs: false,
        });
    } else {
        item.push({
            type: 'action',
            actionName: ''
        });
    }
}

function addVat() {
    importer.value.vats.push({
        vat: 0,
        name: '',
        items: []
    });
}

function getImporterJson() {
    return JSON.stringify(importer.value);
}

function evaluateResult(group, index) {

    let itemToProcess = importer.value[group];
    if (index !== undefined) {
        itemToProcess = importer.value[group][index].items;
    }
    if (!itemToProcess) {
        return '';
    }

    let actionName = '';
    let result = 0;
    for (let i = 0; i < itemToProcess.length; i++) {
        const item = itemToProcess[i];
        if (item.type === 'column' && item.columnName !== '') {
            const valueFromSample = parseFloat(getSampleValue(item));
            const readValue = !_.isNaN(valueFromSample) ? valueFromSample : 0;
            if (actionName !== '') {
                switch (actionName) {
                    case 'add':
                        result += readValue;
                        break;
                    case 'subtract':
                        result -= readValue;
                        break;
                    case 'multiply':
                        result *= readValue;
                        break;
                }
                actionName = '';
            } else {
                result = readValue;
            }
        } else {
            actionName = item.actionName;
        }
    }
    return _.round(result, 2);
}

function evaluateVat(index) {
    const item = importer.value['vats'][index];
    let result = evaluateResult('vats', index);

    switch (item.vat) {
        case -23:
            result = result * 0.187;
            break;
        case -8:
            result = result * 0.08 / 1.08;
            break;
        case 8:
            result = result * 0.08;
            break;
        case 23:
            result = result * 0.23;
            break;
    }

    return Math.abs(_.round(result, 2));
}

function evaluateString(group, separator) {
    const importerData = importer.value[group];
    if (importerData.length === 0) {
        return '';
    }
    let result = props.sampleData[importerData[0].columnName];
    if (importerData.length === 1) {
        return result;
    }
    for (let i = 1; i < importerData.length; i++) {
        const item = importerData[i];
        if (item.type === 'column' && props.sampleData[item.columnName]) {
            result += separator + props.sampleData[item.columnName];
        }
    }
    return result;
}

function canShowColumnButton(group, index) {

    let groupItems = importer.value[group];
    if (index !== undefined) {
        groupItems = importer.value[group][index].items;
    }

    return groupItems.length === 0 || groupItems[groupItems.length - 1].type === 'action';
}

function canShowActionButton(group, index) {

    let groupItems = importer.value[group];
    if (index !== undefined) {
        groupItems = importer.value[group][index].items;
    }

    return groupItems.length > 0 && groupItems[groupItems.length - 1].type === 'column';
}

function getOptionLabel(option) {

    let foundIndex;
    // find index of option in columnNames
    props.columnNames.forEach((element, index) => {
        if (element === option) {
            foundIndex = index;

        }
    });
    if (foundIndex) {
        return `${foundIndex}: ${option}`;
    }
    return option;
}
</script>

<template>
    <div v-for="(groupName, groupKey) in baseGroups" class="mb-4">
        <h3>{{ groupName }}</h3>
        <table class="table table-striped">
            <thead>
            <tr>
                <th class="w-50">Proces</th>
                <th class="w-25">Inne</th>
                <th class="w-25">Przykładowe dane</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="importerItem in importer[groupKey]">
                <td>
                    <div v-if="importerItem.type==='column'">
                        <v-select :clearable="false"
                                  :getOptionLabel="getOptionLabel"
                                  v-model="importerItem.columnName"
                                  :options="columnNames">

                        </v-select>
                    </div>
                    <div v-if="importerItem.type==='action'">
                        <v-select :clearable="false"
                                  :reduce="item => item.value"
                                  :searchable="false"
                                  v-model="importerItem.actionName"
                                  :options="actionNames">
                        </v-select>
                    </div>
                </td>
                <td>
                    <div v-if="importerItem.type==='column'">
                        <label>
                            <input type="checkbox" v-model="importerItem.isAbs">
                            Wartość bezwzględna
                        </label>
                    </div>
                </td>
                <td>
                    <div v-if="importerItem.type==='column'">
                        {{ getSampleValue(importerItem) }}

                    </div>
                    <div v-if="importerItem.type==='action'">
                        {{ actionNames.find(action => action.value === importerItem.actionName)?.label }}
                    </div>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <td></td>
                <td></td>
                <td>
                    {{ evaluateResult(groupKey) }}
                </td>
            </tr>
            </tfoot>
        </table>
        <button v-if="canShowColumnButton(groupKey)" type="button" class="btn btn-primary"
                @click="addItem(groupKey,'column')">
            Dodaj kolumnę
        </button>
        <button v-if="canShowActionButton(groupKey)" type="button" class="btn btn-primary"
                @click="addItem(groupKey,'action')">
            Dodaj działanie
        </button>
        <button type="button" class="btn btn-outline-danger"
                v-if="importer[groupKey].length>0"
                @click="removeItem(groupKey)">
            Usuń ostatni rekord
        </button>
    </div>
    <h2>VATy</h2>
    <div v-for="(vat, index) in importer.vats" class="mb-4">
        <label>
            Nazwa:
            <input type="text" v-model="vat.name" class="form-control">
        </label>
        <p>Stawka:
            <v-select :clearable="false"
                      v-model="vat.vat"
                      :reduce="item => item.value"
                      :options="vats"></v-select>
        </p>
        <table class="table table-striped">
            <thead>
            <tr>
                <th class="w-50">Proces</th>
                <th class="w-25">Inne</th>
                <th class="w-25">Przykładowe dane</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="importerItem in vat.items">
                <td>
                    <div v-if="importerItem.type==='column'">
                        <v-select :clearable="false"

                                  :getOptionLabel="getOptionLabel"
                                  v-model="importerItem.columnName"
                                  :options="columnNames">

                        </v-select>
                    </div>
                    <div v-if="importerItem.type==='action'">
                        <v-select :clearable="false"
                                  :reduce="item => item.value"
                                  :searchable="false"
                                  v-model="importerItem.actionName"
                                  :options="actionNames">
                        </v-select>
                    </div>
                </td>

                <td>
                    <div v-if="importerItem.type==='column'">
                        <label>
                            <input type="checkbox" v-model="importerItem.isAbs">
                            Wartość bezwzględna
                        </label>
                    </div>
                </td>
                <td>
                    <div v-if="importerItem.type==='column'">
                        {{ getSampleValue(importerItem) }}
                    </div>
                    <div v-if="importerItem.type==='action'">
                        {{ actionNames.find(action => action.value === importerItem.actionName)?.label }}
                    </div>
                </td>
            </tr>
            </tbody>
            <tfoot v-if="vat.items.length>0">
            <tr>
                <td>Podstawa</td>
                <td></td>
                <td>
                    {{ evaluateResult('vats', index) }}
                </td>
            </tr>
            <tr>
                <td>Wartość VAT</td>
                <td></td>
                <td>
                    {{ evaluateVat(index) }}
                </td>
            </tr>
            </tfoot>
        </table>
        <button v-if="canShowColumnButton('vats',index)" type="button" class="btn btn-primary"
                @click="addItem('vats','column',index)">
            Dodaj kolumnę
        </button>
        <button v-if="canShowActionButton('vats',index)" type="button" class="btn btn-primary"
                @click="addItem('vats','action',index)">
            Dodaj działanie
        </button>
        <button type="button" class="btn btn-outline-danger"
                v-if="importer['vats'][index].items.length>0"
                @click="removeItem('vats',index)">
            Usuń ostatni rekord
        </button>
    </div>
    <button type="button" class="btn btn-secondary"
            @click="addVat()">
        Dodaj VAT
    </button>
    <button type="button" class="btn btn-outline-danger"
            v-if="importer['vats'].length>0"
            @click="removeItem('vats')">
        Usuń ostatni vat
    </button>


    <div class="my-4" v-if="importer">
        <h3>Id używane do identyfikacji kierowcy</h3>
        <table class="table table-striped">
            <thead>
            <tr>
                <th class="w-50">Pola</th>
                <th class="w-50">Przykładowe ID</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="importerItem in importer['externalId']">
                <td>
                    <v-select :clearable="false"

                              :getOptionLabel="getOptionLabel"
                              v-model="importerItem.columnName"
                              :options="columnNames">

                    </v-select>

                </td>
                <td>
                    <div v-if="importerItem.type==='column'">
                        {{ sampleData[importerItem.columnName] }}
                    </div>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <td></td>
                <td>
                    {{ evaluateString('externalId', ':') }}
                </td>
            </tr>
            </tfoot>
        </table>
        <button type="button" class="btn btn-primary"
                @click="addItem('externalId','column')">
            Dodaj kolumnę
        </button>
        <button type="button" class="btn btn-outline-danger"
                v-if="importer['externalId'].length>0"
                @click="removeItem('externalId')">
            Usuń ostatni rekord
        </button>


    </div>
    <div class="mb-4" v-if="importer">
        <h3>Dane dodatkowe do sald oczekujących</h3>
        <table class="table table-striped">
            <thead>
            <tr>
                <th class="w-50">Pola</th>
                <th class="w-50">Przykładowe dane</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="importerItem in importer['additionalData']">
                <td>
                    <v-select :clearable="false"

                              :getOptionLabel="getOptionLabel"
                              v-model="importerItem.columnName"
                              :options="columnNames">

                    </v-select>

                </td>
                <td>
                    <div v-if="importerItem.type==='column'">
                        {{ sampleData[importerItem.columnName] }}
                    </div>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <td></td>
                <td>
                    {{ evaluateString('additionalData', ' ') }}
                </td>
            </tr>
            </tfoot>
        </table>
        <button type="button" class="btn btn-primary"
                @click="addItem('additionalData','column')">
            Dodaj kolumnę
        </button>
        <button type="button" class="btn btn-outline-danger"
                v-if="importer['additionalData'].length>0"
                @click="removeItem('additionalData')">
            Usuń ostatni rekord
        </button>
    </div>
    <div class="mb-4" v-if="importer && importer.filter">
        <h3><label><input type="checkbox" v-model="importer.filter.active"> Filtrowanie danych</label></h3>
        <table class="table table-striped" v-if="importer.filter.active">
            <thead>
            <tr>
                <th>Pole</th>
                <th>Warunek</th>
                <th>Wartość</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    <v-select :clearable="false"
                              :getOptionLabel="getOptionLabel"
                              v-model="importer.filter.field"
                              :options="columnNames">

                    </v-select>
                </td>
                <td>
                    <v-select :clearable="false"
                              v-model="importer.filter.condition"
                              :options="['=', '!=', '>', '<', '>=', '<=']">

                    </v-select>
                </td>
                <td>
                    <input type="text" v-model="importer.filter.value" class="form-control">
                </td>
            </tr>

            </tbody>

        </table>

    </div>

    <input type="hidden" name="importer" :value="getImporterJson()">
    {{ importer }}
</template>

<style scoped>

</style>
